





























































import axios from "axios";
import Vue from "vue";

axios.defaults.withCredentials = true;

import { D_ShortenUrlData } from "@/type/D_ShortenUrlData";
import { isValidEllaUrl } from "@/lib/Validators";

export default Vue.extend({
  name: "ShortenUrl",
  components: {},
  data(): D_ShortenUrlData {
    return {
      errorMessage: "",
      formIsValid: false,
      isSubmitting: false,
      showError: false,
      showSummary: false,
      url: "https://ella.ochin.org",
      urlLong: "",
      urlShort: "",
      urlValidationList: [
        (url: string | null) => !!url || "URL is required.",
        (url: string | null) =>
          isValidEllaUrl(url) ||
          "URL format is not valid (must begin with 'https://ella.ochin.org').",
      ],
    };
  },
  methods: {
    copyUrl(): void {
      const shortenedUrlElement = this.$refs.shortenedUrl as Vue;
      const shortenedUrlField = shortenedUrlElement.$el.querySelector("input");

      if (shortenedUrlField) {
        shortenedUrlField.select();
        document.execCommand("copy");
      }
    },

    async shorten(): Promise<void> {
      this.resetForm();

      let result;
      try {
        result = await axios({
          data: {
            // eslint-disable-next-line
            url_long: this.url,
          },
          method: "post",
          url: "https://url.ochin.info/admin_shrink_url",
        });
        if (result.data.errorType) {
          this.showErrorAlert(result.data.errorMessage);
          return;
        }
      } catch (e) {
        this.showErrorAlert(e.toString());
        return;
      }

      const {
        urlLong,
        urlShort,
      }: { urlLong: string; urlShort: string } = result.data;

      this.urlLong = urlLong;
      this.urlShort = urlShort;
      this.showSummary = true;
    },
    resetForm(): void {
      this.showError = false;
      this.showSummary = false;
    },
    showErrorAlert(message: string) {
      this.errorMessage = message;
      this.showError = true;
    },
  },
});
