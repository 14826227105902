



























import axios from "axios";
import Vue from "vue";

import { D_LoginData } from "@/type/D_LoginData";

axios.defaults.withCredentials = true;

export default Vue.extend({
  name: "Login",
  components: {},
  data(): D_LoginData {
    return {
      isLoggingIn: false,
      password: "",
      username: "",
    };
  },
  methods: {
    async login() {
      this.isLoggingIn = true;

      try {
        await axios({
          data: {
            password: this.password,
            username: this.username,
          },
          method: "post",
          url: "https://url.ochin.info/login",
        });
      } catch (e) {
        console.error(e);
        this.isLoggingIn = false;
        return;
      }

      this.$router.push({ name: "ShortenUrl" });
    },
  },
});
