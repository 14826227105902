interface F_IsValidUrl {
  (url: string | null): boolean;
}

function testUrl(regex: RegExp, url: string | null): boolean {
  if (!url) return false;
  return regex.test(url);
}

export const isValidEllaUrl: F_IsValidUrl = function(url) {
  const regex = /^https:\/\/ella.ochin.org([/?][a-z0-9-._~:/?#[\]@!$&'()*+,;=]*)?$/i;
  return testUrl(regex, url);
};

export const isValidUrl: F_IsValidUrl = function(url) {
  // https://mathiasbynens.be/demo/url-regex
  const regex = /(https?):\/\/(-.)?([^s/?.#-]+.?)+(\/[^s]*)?$/i;
  return testUrl(regex, url);
};
