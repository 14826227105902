
















import Vue from "vue";
import { removeCookie } from "tiny-cookie";

export default Vue.extend({
  name: "App",
  methods: {
    logout() {
      removeCookie("urlShortenerJwt", { domain: "ochin.info" });
      this.$router.push({ name: "Login" });
    },
  },
});
