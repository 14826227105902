import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { getCookie } from "tiny-cookie";

import Login from "@/views/Login.vue";
import ShortenUrl from "@/views/ShortenUrl.vue";

function isLoggedIn(): boolean {
  const cookie = getCookie("urlShortenerJwt");

  if (!cookie) return false;
  return true;
}

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Login",
    component: Login,
    beforeEnter(to, from, next) {
      if (isLoggedIn()) {
        next({ name: "ShortenUrl" });
        return;
      }
      next();
    },
    meta: { title: "OCHIN URL Shortener" },
  },
  {
    path: "/shorten-url",
    name: "ShortenUrl",
    component: ShortenUrl,
    beforeEnter(to, from, next) {
      if (isLoggedIn()) {
        next();
        return;
      }
      next({ name: "Login" });
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
